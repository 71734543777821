/* eslint-disable max-len */
import fetch from '../src/services/fetch';
import type { Params } from '../src/components/oneweb/Video/view/flowTypes';

import DataRepositorySite from "./model/DataRepositorySite";
import DataPageSet from "./model/DataPageSet";
import DataPageTemplate from "./model/DataPageTemplate";
import DataPage from "./model/DataPage";
import DataPageTemplateSet from "./model/DataPageTemplateSet";
import DataSite from "./model/DataSite";
import DataPageStylesheet from "./model/DataPageStylesheet";
import DataPageTemplateRef from './model/DataPageTemplateRef';
import type { DataPageTemplateRefPage } from './model/DataPageTemplateRef';
import * as Types from './flowTypes';
import isDevEnv from '../src/debug/isDevEnv';
import type { SiteSettings } from '../src/components/App/epics/siteSettings/flowTypes';
import * as array from '../utils/array.js';
import { ApiUrl } from './ApiUrl';
import { getAppConfig } from '../src/components/App/epics/appConfig/appConfig.js';
import { getSstockImagesPurchaseMockQuery } from '../src/components/FileManager/shutterstock/getSstockImagesPurchaseMockQuery';
import { RAW_ASSET_ENDPOINT } from '../../server/shared/constants.js';
import { backMapItemsToSaveData } from './pageMapAdapter/mappersForSave/index';
import type { ThemeColorDataType } from "../src/components/ThemeGlobalData/flowTypes";
import type { DeleteWebspaceResourceOptions } from '../src/redux/modules/children/fileChooser/flowTypes';

import { makeUrl } from './makeUrl';
import { makeRequest, makeCachedRequest, makeSaveRequest, makeDeleteRequest } from './makeRequest';
import { wrapModelResponse, wrapCollectionModelResponse, makeResponseSetMapper } from './makeResponse';
import { updateRelatedPageDataSetCaches, updatePageDataSetCache } from './pageDataSetCache';
import { makeWebSpaceUrlFactory as makeWebSpaceUrlDefaultFactory } from './makeWebSpaceUrlFactory';

import {
    WEBSHOP_PREVIEW,
    DAL_API_PREFIX,
    TEMPLATE_DATA_COMPONENT,
    STYLESHEET_DATA,
    SOURCE_REPOSITORY,
    SITE_SETTINGS,
    SITE_DATA,
    PAGE_DATA_COMPONENT,
    THIRD_PARTY_CONNECTIONS
} from './constants';
import { DalRequestMethod } from './flowTypes';
import NamedBackup from "./model/NamedBackup";

const REQUEST_METHODS: Record<DalRequestMethod, DalRequestMethod> = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
};

export const createDalWithWebSpaceUrlFactory = (
    domain: string,
    makeWebSpaceUrlFactory: Function = makeWebSpaceUrlDefaultFactory
): Record<string, any> => {
    const
        version = 'v1',
        defaultPath = [DAL_API_PREFIX, version, domain].join('/'),
        docPath = (prefix: string) => [DAL_API_PREFIX, version, prefix, 'doc'].join('/'),
        domainDocPath = docPath(domain),
        domainDocTemplateComponentPath = (id: string) => `${domainDocPath}/${TEMPLATE_DATA_COMPONENT}/${id}`,
        domainDocStylesheetComponentPath = (id: string) => `${domainDocPath}/${STYLESHEET_DATA}/${id}`,
        repositoryDocPath = docPath(SOURCE_REPOSITORY),
        apiUrl = new ApiUrl(domain);

    const makeWebSpaceUrl = makeWebSpaceUrlFactory(version, defaultPath);

    const getCachedDataComponent = (source: string, type: string, id: string, all = false, config: { flagAutoColorActive?: boolean } = {}): Promise<any> => {
        const
            sourcePath = docPath(source),
            cacheKey = type + ':' + id;

        const query: { all: boolean, applyAutoColor?: boolean } = {
            all
        };
        if (config.flagAutoColorActive) {
            query.applyAutoColor = !!config.flagAutoColorActive;
        }
        return makeCachedRequest(cacheKey, {
            url: makeUrl({
                path: sourcePath + '/' + type + '/' + id,
                query
            }),
            options: {
                method: 'GET'
            }
        });
    };

    const getDataComponent = (source: string, type: string, id: string, all = false): Promise<any> => {
        const sourcePath = docPath(source);
        return makeRequest({
            url: makeUrl({ path: sourcePath + '/' + type + '/' + id, query: { all } }),
            options: {
                method: 'GET'
            }
        });
    };

    const getWebSpaceContent = (path, forceFetch = false, opts: Record<string, any> = {}) => {
        const
            query = opts.glob && { glob: opts.glob },
            suffix = path && !opts.glob ? '/' : '';

        return makeCachedRequest(path, {
            url: makeWebSpaceUrl({ path, query }) + suffix,
            options: {
                method: 'GET',
                query: opts.glob && { glob: opts.glob },
            }
        }, forceFetch);
    };

    return {
        getDomain: (): string => domain,

        // TODO: Clean up
        // isRepositoryDomain: (): boolean => domain === SOURCE_REPOSITORY,
        isRepositoryDomain: (): boolean => {
            let isRepository = false;
            if (
                domain.indexOf('.') === -1 &&
                (
                    domain === 'repository' ||
                    domain.indexOf('design-') === 0 ||
                    domain.indexOf('page-layout-') === 0 ||
                    domain.indexOf('component-layout-') === 0
                )
            ) {
                isRepository = true;
            }
            return isRepository;
        },

        makeWebshopPreviewUrl: query => {
            return makeUrl({ path: defaultPath + '/' + WEBSHOP_PREVIEW + '/v2/iframe', query, options: { encodeQuery: true } });
        },

        getAppViewToken: (app: string) => {
            return makeRequest({
                url: makeUrl({ path: `${defaultPath}/appAccessToken/${app}` }),
                options: {
                    method: 'GET'
                }
            });
        },

        getPreferences: () => makeRequest({
            url: apiUrl.preferencesWebEditorUiState(),
            options: { method: 'GET' },
        }),

        putPreferences: (
            request: Types.PutPreferencesRequestBody,
        ): Promise<Types.DalResponseSet<Types.PutPreferencesResponseBody>> =>
            makeSaveRequest(apiUrl.preferencesWebEditorUiState(), request),

        // @ts-ignore We can have some initial values
        getSiteData: ({ time, noHeaders } = {}): Promise<Types.DalResponseSet<DataSite>> => {
            return makeRequest({
                url: makeUrl({ path: defaultPath + '/doc/web.data.Site/site', query: { time } }),
                options: {
                    method: 'GET',
                    timeout: '10s',
                },
                noHeaders
            }).then(wrapModelResponse(DataSite));
        },

        getSitePages: ({ stubs }): Promise<Types.DalResponseSet<Array<DataPage>>> => {
            return makeRequest({
                url: makeUrl({ path: `${defaultPath}/doc/web.data.components.Page`, query: { stubs } }),
                options: {
                    method: 'GET',
                    timeout: '30s',
                }
            }).then(wrapCollectionModelResponse(DataPage));
        },

        loadSiteSettings: () => {
            return makeRequest({
                url: makeUrl({ path: defaultPath + `/doc/${SITE_SETTINGS}/settings` }),
                options: {
                    method: 'GET'
                }
            });
        },

        createDefaultSiteSettings: (siteSettingsDefaultState: SiteSettings) => {
            return makeRequest({
                url: makeUrl({ path: defaultPath + `/doc/${SITE_SETTINGS}/settings` }),
                options: {
                    method: "PUT",
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(siteSettingsDefaultState)
                }
            });
        },

        enableThemingRequest: ({ themeColors, templateId, returnSiteSettings }: {
            themeColors: ThemeColorDataType, templateId: string, returnSiteSettings: boolean
        }) => {
            let query = {
                doUpdate: 'yes',
                themeColors: themeColors ? JSON.stringify(themeColors) : "",
                currentTemplateId: templateId,
            };

            if (returnSiteSettings) {
                // @ts-ignore
                query.siteSettings = 'yes';
            }
            const url = makeUrl({
                path: defaultPath + '/theming/computeThemeColors/all',
                query
            });
            const request = {
                url,
                options: {
                    method: REQUEST_METHODS.GET,
                    headers: { 'Content-Type': 'application/json' }
                }
            };
            return makeRequest(request);
        },
        computeThemeColorsForOneTemplate: (templateId: string) => {
            const request = {
                url: defaultPath + '/theming/computeThemeColors/' + templateId,
                options: {
                    method: REQUEST_METHODS.GET,
                    headers: { 'Content-Type': 'application/json' }
                }
            };
            return makeRequest(request);
        },

        computeThemeDataForTemplateSelector: ({ designTemplateId, designName }) => {
            const url = makeUrl({
                path: [DAL_API_PREFIX, version, designName].join('/') + '/theming/computeThemeColors/' + designTemplateId,
                query: {
                    siteMap: 'yes',
                    siteSettings: 'yes',
                    localizationsByTemplateId: 'yes',
                    // NOTE: This is added to prevent theme colors calculation and migration of components for already migrated templates.
                    // Now onwards all templates are auto color migrated automatically by script.
                    preventComputation: 'yes',
                }
            });
            const request = {
                url,
                options: {
                    method: REQUEST_METHODS.GET,
                    headers: { 'Content-Type': 'application/json' }
                }
            };
            return makeRequest(request);
        },

        checkTranscodeStatus: (videoName?: string) => {
            const request = {
                url: makeUrl({ path: defaultPath + '/wsbVideoStatus', query: { videoName } }),
                options: {
                    method: REQUEST_METHODS.GET
                }
            };
            return makeRequest(request);
        },

        checkPublishStatus: (publicationId?: number) => {
            const request = {
                url: makeUrl({ path: defaultPath + '/publish', query: { publicationId } }),
                options: {
                    method: REQUEST_METHODS.GET
                }
            };
            return makeRequest(request);
        },

        publishSite: ({
            locale,
            force,
            forceAndGmbForce,
            forceAllowInvalidEmails,
            currentPageId
        }) => {
            const request = {
                url: defaultPath + '/publish',
                options: {
                    method: REQUEST_METHODS.POST,
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        locale,
                        force,
                        forceAndGmbForce,
                        forceAllowInvalidEmails,
                        currentPageId
                    })
                }
            };
            return makeRequest(request);
        },

        fixAllHeaderLayout: (params) => {
            return makeRequest({
                url: `${defaultPath}/fixAllHeaderLayout`,
                options: {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(params)
                }
            });
        },

        updateSiteData: ({
            site,
            deletedPages,
            addedPages,
            updatedTemplate
        }: Types.UpdateSiteDataInput): Promise<Types.DalResponseSet<Types.UpdateSiteDataResponse>> => {
            const updateDoc: Record<string, any> = { update: [site] };
            if (deletedPages) updateDoc.delete = deletedPages;
            if (addedPages) updateDoc.add = addedPages;
            if (updatedTemplate) updateDoc.update.push(updatedTemplate);

            return makeSaveRequest(`${domainDocPath}/batch`, updateDoc, 'POST')
                .then(makeResponseSetMapper((batchResponseBody: Types.DalBatchResponse) => {
                    if (!Array.isArray(batchResponseBody.update) || batchResponseBody.update[0].type !== SITE_DATA) {
                        throw new Error(`Incorrect server response. Expecting batch update to have site`);
                    }

                    const newResponseBody: Types.UpdateSiteDataResponse = { site: batchResponseBody.update[0] };
                    if (deletedPages) newResponseBody.deletedPages = batchResponseBody.delete;
                    if (addedPages) newResponseBody.addedPages = batchResponseBody.add;
                    if (updatedTemplate) {
                        newResponseBody.updatedTemplate = batchResponseBody.update[1];
                        updateRelatedPageDataSetCaches({
                            docs: { template: updatedTemplate },
                            newResponseBody: { template: newResponseBody.updatedTemplate }
                        });
                    }

                    return newResponseBody;
                }));
        },

        updateSiteDocuments: (
            docs: Types.UpdateSiteDocumentsInput
        ): Promise<Types.DalResponseSet<Types.UpdateSiteDocumentsResponse>> => {
            const
                { page, template, stylesheet, siteSettings, site, thirdPartyConnections, premiumFeatures } = docs,
                docsToUpdate: any = [];

            if (page) docsToUpdate.push(backMapItemsToSaveData(page));
            if (template) docsToUpdate.push(backMapItemsToSaveData(template));
            if (stylesheet) docsToUpdate.push(stylesheet);
            if (siteSettings) docsToUpdate.push(siteSettings);
            if (site) docsToUpdate.push(site);
            if (thirdPartyConnections) docsToUpdate.push(thirdPartyConnections);
            if (premiumFeatures) docsToUpdate.push(premiumFeatures);

            if (docsToUpdate.length === 0) throw Error('Empty input given. Nothing to update.');

            return makeSaveRequest(`${domainDocPath}/batch`, { update: docsToUpdate }, 'POST').then(
                makeResponseSetMapper(
                    (batchResponseBody: Types.DalBatchResponse): Types.UpdateSiteDocumentsResponse => {
                        // check if response is consistent with input
                        let checked = false;
                        if (Array.isArray(batchResponseBody.update)) {
                            const
                                responseDocTypes = batchResponseBody.update.map(res => res.type),
                                inputDocTypes = docsToUpdate.map(doc => doc.type);

                            if (array.equal(responseDocTypes, inputDocTypes, true /* order */)) {
                                checked = true;
                            }
                        }

                        if (!checked) {
                            throw new Error(`Incorrect server response. Some of the docs could not be saved.`);
                        }

                        // Prepare output
                        const
                            newResponseBody: Record<string, any> = {},
                            outputByType = (response, outputType) => response.filter(r => r.type === outputType).pop();

                        if (batchResponseBody.update) {
                            if (page) {
                                newResponseBody.page = outputByType(batchResponseBody.update, PAGE_DATA_COMPONENT);
                            }
                            if (template) {
                                newResponseBody.template = outputByType(
                                    batchResponseBody.update,
                                    TEMPLATE_DATA_COMPONENT
                                );
                            }
                            if (stylesheet) {
                                newResponseBody.stylesheet = outputByType(batchResponseBody.update, STYLESHEET_DATA);
                            }
                            if (siteSettings) {
                                newResponseBody.siteSettings = outputByType(batchResponseBody.update, SITE_SETTINGS);
                            }
                            if (site) {
                                newResponseBody.site = outputByType(batchResponseBody.update, SITE_DATA);
                            }
                            if (thirdPartyConnections) {
                                newResponseBody.thirdPartyConnections = outputByType(batchResponseBody.update, THIRD_PARTY_CONNECTIONS);
                            }
                        }

                        // Update page's cache and cache of pages related to templte/stylesheet
                        if (page) {
                            updatePageDataSetCache({ pageId: page.id, docs, newResponseBody });
                        }

                        if (docs.template || docs.stylesheet) {
                            const batchDocs = { template: docs.template, stylesheet: docs.stylesheet };
                            updateRelatedPageDataSetCaches({
                                docs: batchDocs,
                                newResponseBody
                            });
                        }
                        return newResponseBody;
                    }
                )
            );
        },

        getDesignSiteData: (designAccountName: string): Promise<any> => {
            // TODO: Generate "apiPathForAccount" via a function
            const apiPathForAccount = `/api/v1/${designAccountName}/doc`,
                url = apiPathForAccount + '/' + SITE_DATA + '/site';
            return makeCachedRequest(
                url,
                {
                    url,
                    options: {
                        method: 'GET'
                    }
                }
            ).then(wrapModelResponse(DataRepositorySite));
        },

        getDesignSiteSettings: (designAccountName: string): Promise<any> => {
            const apiPathForAccount = `/api/v1/${designAccountName}/doc`,
                url = apiPathForAccount + '/' + SITE_SETTINGS + '/settings';
            return makeCachedRequest(
                url,
                {
                    url,
                    options: {
                        method: 'GET'
                    }
                }
            );
        },

        getDesignLocalizations: (designAccountName: string): Promise<any> => {
            // TODO: Generate "apiPathForAccount" via a function
            const url = `/designLocalizations/?designAccountName=${designAccountName}`;
            return makeCachedRequest(
                url,
                {
                    url,
                    options: {
                        method: 'GET'
                    }
                }
            );
        },
        saveDesignLocalizations: (i18n): Promise<any> => {
            return makeSaveRequest(
                defaultPath + '/saveDesignLocalizations', i18n, 'PUT'
            );
        },

        getDesignPage: (id: string, designAccountName: string): Promise<Types.DalResponseSet<DataPage>> => {
            // TODO: Generate "apiPathForAccount" via a function
            const apiPathForAccount = `/api/v1/${designAccountName}/doc`;
            return makeRequest({
                url: apiPathForAccount + '/' + PAGE_DATA_COMPONENT + '/' + id,
                options: {
                    method: 'GET'
                }
            }).then(wrapModelResponse(DataPage));
        },

        getPageLayoutsData: (): Promise<any> => {
            return makeCachedRequest('list-of-page-layouts.json', {
                url: '/assets/list-of-page-layouts/list-of-page-layouts.json',
                options: {
                    method: 'GET'
                }
            });
        },

        // TODO: Cleanup. This function wouldn't be required eventually
        getRepositorySiteData: (): Promise<any> => {
            // return makeCachedRequest('web.data.Site', {
            //     url: repositoryDocPath + '/web.data.Site/site',
            //     options: {
            //         method: 'GET'
            //     }
            // }).then(wrapModelResponse(DataRepositorySite));
            return makeCachedRequest('list-of-designs', {
                url: '/list-of-designs',
                options: {
                    method: 'GET'
                }
            }).then(wrapModelResponse(DataRepositorySite));
        },

        getSectionsBlocksListData: (): Promise<any> => {
            return makeCachedRequest('list-of-sections-blocks', {
                url: '/list-of-sections-blocks',
                options: {
                    method: 'GET'
                }
            });
        },

        getSelectedTemplate: (data): Promise<any> => {
            return makeRequest({
                url: makeUrl({
                    path: `/selectedTemplate`,
                    query: data
                }),
                options: {
                    method: 'GET'
                }
            });
        },

        getWebshopData: (endpoint: string): Promise<any> => {
            return makeRequest({
                url: defaultPath + `/webshopApi/${endpoint.replace(/^\/+/, '')}`,
                options: {
                    method: 'GET'
                }
            });
        },

        getBookingsData: (endpoint: string): Promise<any> => {
            return makeRequest({
                url: defaultPath + `/bookingsApi/${endpoint.replace(/^\/+/, '')}`,
                options: {
                    method: 'GET'
                }
            });
        },

        getRevenue: (data: Record<string, any>): Promise<any> => {
            return makeRequest({
                url: makeUrl({
                    path: `${defaultPath}/webshopRevenue/`,
                    query: data,
                    options: { encodeQuery: true }
                }),
                options: {
                    method: 'GET'
                }
            });
        },

        // copyRepositoryTemplate: (templateId: string): Promise<any> => {
        copyRepositoryTemplate: ({ designAccountName, templateIds }): Promise<any> => {
            // TODO: Generate "apiPathForAccount" via a function
            const apiPathForAccount = `/api/v1/${designAccountName}/doc`;
            return makeRequest({
                // url: repositoryDocPath + '/web.data.components.Template/' + templateId + '?all=copy',
                url: apiPathForAccount + '/web.data.components.Template/' + templateIds[0] + '?all=copy',
                options: {
                    method: 'GET'
                }
            });
        },
        putNewStylesheet: (styleSheet): Promise<any> => {
            return makeSaveRequest(
                defaultPath + '/doc/web.data.styles.Stylesheet/' + styleSheet.id, { ...styleSheet }, 'PUT'
            );
        },
        putNewTemplate: (template): Promise<any> => {
            return makeSaveRequest(
                defaultPath + '/doc/web.data.components.Template/' + template.id, { ...template }, 'PUT'
            );
        },

        postDynamicTemplate: (data): Promise<any> => {
            return makeSaveRequest(
                `${defaultPath}/doc/dynamicTemplateImport`, data, 'POST'
            );
        },

        getPageDataSet: (pageId: string): Promise<any> => {
            return getCachedDataComponent(domain, PAGE_DATA_COMPONENT, pageId, true /* all */)
                .then(wrapModelResponse(DataPageSet));
        },

        getTemplateDataSet: (templateId: string): Promise<any> => {
            return getDataComponent(domain, TEMPLATE_DATA_COMPONENT, templateId, true /* all */)
                .then(wrapModelResponse(DataPageTemplateSet));
        },

        getPage: (id: string): Promise<Types.DalResponseSet<DataPage>> => {
            return getCachedDataComponent(domain, PAGE_DATA_COMPONENT, id)
                .then(wrapModelResponse(DataPage));
        },

        // TODO: Is this getting used anywhere ?
        getRepositoryPage: (id: string): Promise<Types.DalResponseSet<DataPage>> => {
            return makeRequest({
                url: repositoryDocPath + '/' + PAGE_DATA_COMPONENT + '/' + id,
                options: {
                    method: 'GET'
                }
            }).then(wrapModelResponse(DataPage));
        },

        getRepositoryTemplate: (id: string): Promise<Types.DalResponseSet<DataPage>> => {
            return makeRequest({
                url: repositoryDocPath + '/' + TEMPLATE_DATA_COMPONENT + '/' + id,
                options: {
                    method: 'GET'
                }
            }).then(wrapModelResponse(DataPageTemplate));
        },

        putPage: (pageData: DataPage) => makeSaveRequest(
            domainDocPath + '/' + PAGE_DATA_COMPONENT + '/' + pageData.id,
            pageData
        ),

        // todo use Uri builder
        makeWebSpaceUrl,

        makePagePreviewUrl: (pageId: string, type: string = 'html', query = {}) => {
            if (!pageId) throw new Error('Missing page id');

            const path = defaultPath + '/preview-wbtgen/' + pageId + '.' + type;
            return makeUrl({ path, query });
        },

        makePagePreviewFromUrl: (query = {}) => {
            const path = defaultPath + '/previewFromUrl';
            return makeUrl({ path, query });
        },

        getWebSpaceContent,

        searchYoutubeVideos: ({ value, nextPageToken }: Params): Promise<any> => makeRequest({
            url: makeUrl({
                path: `${defaultPath}/youtube/search`,
                query: {
                    q: value,
                    part: 'snippet',
                    ...(nextPageToken.length > 0 ? { pageToken: nextPageToken } : {}),
                    maxResults: 20,
                    type: 'video'
                },
                encode: true,
                options: { encodeQuery: true }
            }),
            options: {
                method: 'GET'
            }
        }),

        getBackupRecords: (): Promise<any> => {
            return makeRequest({ url: domainDocPath + '/timeline?threshold=10000', options: { method: 'GET' } });
        },

        requestBackupRestore: (time): Promise<any> => {
            return makeRequest({
                url: domainDocPath,
                options: {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ command: "rollback", time }),
                    emptyResponse: true
                }
            });
        },
        namedBackup: ({ payload, requestType } : { payload: NamedBackup, requestType: 'edit' | 'add' }) => {
            return makeRequest({
                url: domainDocPath + '/timeline/title',
                options: {
                    method: requestType === 'add' ? 'POST' : 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(payload),
                    emptyResponse: true
                }
            });
        },

        getOwnerEmailIds: (): Promise<any> => {
            // IMPORTANT: Always use AppConfigSelector('server.fetchOwnerInfo') in epic condition
            return makeRequest({ url: defaultPath + '/ownerInfo?email=true', options: { method: 'GET' } });
        },

        uploadFromUrl: (url: string, webSpacePath: string) => {
            const
                requestUrl = makeWebSpaceUrl({ path: webSpacePath, query: { url }, options: { encodeQuery: true } }),
                request = { url: requestUrl, options: { method: REQUEST_METHODS.PUT } };

            return makeRequest(request);
        },

        uploadToWebspaceFromOneStore: (fileName: string, webSpacePath: string) => {
            const
                requestUrl = makeUrl({
                    path: defaultPath + '/uploadToWebspaceFromOneStore',
                    query: { fileName, webSpacePath },
                    options: { encodeQuery: true }
                }),
                request = { url: requestUrl, options: { method: REQUEST_METHODS.PUT } };

            return makeRequest(request);
        },

        uploadFromFile: async (file: Record<string, any>, webSpacePath: string) => {
            const
                requestUrl = makeWebSpaceUrl({ path: webSpacePath }),
                request = { url: requestUrl, options: { method: REQUEST_METHODS.PUT, file, headers: { 'content-type': file.type } } };

            return makeRequest(request);
        },
        uploadFromFileVideo: async (file: Record<string, any>, webSpacePath: string) => {
            const reqUrl = makeUrl(
                { path: defaultPath + '/video/createMultipartUpload' }
            );
            const dalResponse = await makeSaveRequest(reqUrl, {
                size: file.size,
                webSpacePath
            }, 'POST');
            const { body, response } = dalResponse;
            if (!response.ok) {
                return dalResponse;
            }
            const { Key, UploadId, urlParts, jobId } = body;
            const CHUNK = 209715200;
            const uploadPartsPromises = urlParts.map((url, index) => {
                const start = index * CHUNK;
                const end = (index + 1) * CHUNK;
                const body = index < (urlParts.length - 1)
                    ? file.slice(start, end)
                    : file.slice(start);
                return fetch(urlParts[index], {
                    method: "PUT",
                    body
                }).then((part) => {
                    /* retrying if etag header is missing */
                    if (!part.headers.get('etag')) {
                        return fetch(urlParts[index], {
                            method: "PUT",
                            body
                        });
                    }
                    return part;
                });
            });
            const resParts = await Promise.all(uploadPartsPromises);

            const hasNullEtag = resParts.some((part) => !part.headers.get('etag'));
            if (hasNullEtag) {
                const url = makeUrl({ path: defaultPath + '/video/abortMultipartUpload' });
                return makeSaveRequest(url, {
                    UploadId,
                    Key,
                    webSpacePath,
                    jobId
                }, 'POST')
                    .then(() => {
                        return Promise.reject('Upload failed.');
                    });
            }
            const parts = resParts.map((part, index) => ({
                PartNumber: index + 1,
                ETag: part.headers.get('etag')
            }));

            // complete multipart upload
            const url = makeUrl({ path: defaultPath + '/video/completeMultipartUpload' });
            return makeSaveRequest(url, {
                UploadId,
                Key,
                Parts: parts,
                webSpacePath,
                jobId
            }, 'POST')
                .then((dalResponse) => {
                    if (!dalResponse.response.ok) {
                        const url = makeUrl({ path: defaultPath + '/video/abortMultipartUpload' });
                        return makeSaveRequest(url, {
                            UploadId,
                            Key,
                            webSpacePath,
                            jobId
                        }, 'POST');
                    }
                    return dalResponse;
                });
        },

        createWebSpaceFolder: (path: string) => makeRequest({
            url: makeWebSpaceUrl({ path }) + '/',
            options: { method: 'PUT', emptyResponse: true }
        }),

        deleteWebspaceResource: ({ webSpacePath, recursive }: DeleteWebspaceResourceOptions) => {
            return makeRequest({
                url: makeWebSpaceUrl({ path: webSpacePath, options: { keepTrailingSlash: true } }),
                options: {
                    method: 'DELETE',
                    emptyResponse: true,
                    headers: recursive ? { depth: 'infinity' } : undefined,
                },
            });
        },

        getResourceMetadata: resourcePath => makeRequest({
            url: makeWebSpaceUrl({ path: resourcePath, query: { metadata: true } }),
            options: {
                method: 'GET'
            }
        }),

        rawAsset: url => {
            const encodedUrl = encodeURI(url);

            return fetch(makeUrl({ path: defaultPath + `/${RAW_ASSET_ENDPOINT}`, query: { url: encodedUrl } })).then(async (response) => {
                const body = await response.text();
                return { response, body };
            });
        },

        getEmailData: () => makeRequest({
            url: makeUrl({ path: defaultPath + '/ownerInfo', query: { email: true } }),
            options: {
                method: 'GET'
            }
        }),

        makeTemplateThumbnailUrl: (id: string, h: number = 490, w: number = 760) =>
            `/screenshots/${w}x${h}/${id}.jpg?auto=false`,

        makePageLayoutThumbnailUrl: (id: string) => `/screenshots/layouts/thumbnails/${id}.png`,

        makePageLayoutPreviewUrl: (id: string) => `/screenshots/layouts/preview/${id}.png`,

        getPageLayoutDataSet: (accountName: string, layoutId: string, config: { flagAutoColorActive?: boolean } = {}) => {
            return getCachedDataComponent(
                accountName,
                PAGE_DATA_COMPONENT,
                layoutId,
                true /* all */,
                {
                    flagAutoColorActive: config.flagAutoColorActive
                }
            );
            // .then(wrapModelResponse(DataPageSet));
        },

        getRepositoryPageDataSet: (layoutId: string) => {
            return getCachedDataComponent(SOURCE_REPOSITORY, PAGE_DATA_COMPONENT, layoutId, true /* all */)
                .then(wrapModelResponse(DataPageSet));
        },

        getPageTemplates: () => {
            return makeRequest({
                url: domainDocPath + '/' + TEMPLATE_DATA_COMPONENT,
                options: {
                    method: 'GET'
                }
            }).then(wrapCollectionModelResponse(DataPageTemplate));
        },

        getTemplatesWithPages: (): Promise<Types.DalResponseSet<Array<DataPageTemplateRef>>> => makeRequest({
            url: `${domainDocPath}/templatesWithPages`,
            options: {
                method: 'GET'
            }
        }).then(wrapCollectionModelResponse(DataPageTemplateRef)),

        getTemplatePages: (templateId: string): Promise<Types.DalResponseSet<Array<DataPageTemplateRefPage>>> =>
            makeRequest({
                url: `${domainDocPath}/templatePages/${templateId}`,
                options: { method: 'GET' }
            }),

        makePageTemplatePreviewUrl: (templateId: string, inOptions = {}): string => {
            const
                defaultOptions = {
                    width: 160,
                    height: 160,
                    cropw: 160,
                    croph: 160,
                    screenWidth: 1024
                },
                options: Record<string, number> = { ...defaultOptions, ...inOptions },
                zoom = options.width / options.screenWidth;

            options.zoom = zoom;
            delete options.screenWidth;

            return makeUrl({
                path: defaultPath + '/preview-wbtgen/png',
                query: {
                    templateselectorpreview: 'desktop',
                    body: encodeURIComponent(JSON.stringify({
                        page: {
                            type: PAGE_DATA_COMPONENT,
                            // id should be set but actual value doesn't matter for server
                            // though, it should be static when passed to LazyImage, to eliminate component rerendering
                            id: '964814EF-39AE-46BB-9C21-D7DBB3074712',
                            templateId,
                            containers: [] // TODO: need this ?
                        }
                    })),
                    ...options
                }
            });
        },

        putPageTemplate: (template: DataPageTemplate): Promise<Record<string, any>> => makeSaveRequest(
            domainDocTemplateComponentPath(template.id),
            template
        ),

        copyPageTemplateRef: (templateId: string): Promise<Types.CopyPageTemplateRefResponse> =>
            // @ts-ignore TODO: WBTGEN-12886
            makeRequest({
                url: apiUrl.copyTemplateRef(templateId),
                options: {
                    method: 'PUT'
                }
            }).then(wrapModelResponse(DataPageTemplateRef)),

        deletePageTemplate: (templateId: string): Promise<Record<string, any>> => makeDeleteRequest(
            domainDocTemplateComponentPath(templateId)
        ),

        deletePageTemplateAll: (
            templateId: string
        ): Promise<Types.DalResponseSet<Types.DeletePageTemplateAllResponseBody>> =>
            makeDeleteRequest(
                apiUrl.deleteTemplateAll(templateId),
                false /* emptyResponse */
            ),

        putPageStylesheet: (stylesheet: DataPageStylesheet): Promise<Record<string, any>> => makeSaveRequest(
            domainDocStylesheetComponentPath(stylesheet.id),
            stylesheet
        ),

        deletePageStylesheet: (stylesheetId: string): Promise<Record<string, any>> => makeDeleteRequest(
            domainDocStylesheetComponentPath(stylesheetId)
        ),

        getDirectoryContents: (directories: Array<string>): Array<Promise<Record<string, any>>> =>
            directories.map(directory => getWebSpaceContent(directory).then(response => response)),

        devBatchPost: (batch: Record<string, any>) => {
            if (!isDevEnv()) throw new Error('devPostBatch() is only allowed in dev mode');
            return makeSaveRequest(`${domainDocPath}/batch`, batch, 'POST');
        },

        getSubscriptionStatus: () => {
            return makeRequest({
                url: defaultPath + '/subscriptionStatus',
                options: {
                    method: 'GET'
                }
            });
        },

        getSubscriptionMetadata: () => {
            return makeRequest({
                url: [DAL_API_PREFIX, version, 'subscription', 'metadata'].join('/'),
                options: {
                    method: 'GET'
                }
            });
        },

        getGoogleAdsCampaignFromCRM: () => {
            return makeRequest({
                url: defaultPath + '/googleAdsCampaign',
                options: {
                    method: 'GET'
                }
            });
        },

        getTrialImportAvailability: (ownerEmail: string) => {
            return makeRequest({
                url: apiUrl.getTrialImportAvailability({ ownerEmail }),
                options: {
                    method: 'GET'
                }
            });
        },

        importTrialData: (ownerEmail: string, trialId: string, token: string) => {
            return makeRequest({
                url: makeUrl({
                    path: '/trial/import/' + domain,
                    query: {
                        userEmail: ownerEmail,
                        trialId,
                        token,
                    }
                }),
                options: {
                    method: 'POST'
                }
            });
        },

        getTrialGptDemoData: (id: string) => makeRequest({
            url: makeUrl({
                path: '/trial/gpt-demo-data/:id'.replace(':id', id),
            }),
            options: {
                method: 'GET'
            }
        }),

        translate: ({ text }) => {
            return makeRequest({
                url: makeUrl({ path: defaultPath + '/translate', query: { text } }),
                options: {
                    method: 'GET'
                }
            });
        },

        getSubscriptionStatusBackupRestore: () => {
            return makeRequest({
                url: defaultPath + '/subscriptionStatusBackupRestore',
                options: {
                    method: 'GET'
                }
            });
        },

        seamlessUpgradeToPremium: (upgradeType) => {
            return makeRequest({
                url: makeUrl({
                    path: defaultPath + '/seamlessUpgradeToPremium',
                    query: { upgradeType }
                }),
                options: {
                    method: 'GET',
                }
            });
        },

        checkStylesheetDeletable: (stylesheetId, stylesheetNameInComponent) => {
            const prop = 'globalId',
                value = stylesheetId,
                name = stylesheetNameInComponent;

            return makeCachedRequest(
                `prop-${prop}-value-${value}-name-${name}}`,
                {
                    url: `${domainDocPath}?prop=${prop}&value=${value}&name=${name}&stubs=true`,
                    options: { method: 'GET' }
                }
            );
        },

        getWebEditorUiState: () => makeRequest({
            url: `${defaultPath}/preferences/1/webEditorUiState`,
            options: { method: 'GET' }
        }),

        getDomainOwnerData: () => makeRequest({
            url: defaultPath + '/ownerInfo',
            options: {
                method: 'GET'
            }
        }),

        getRepositoryDocsWithType: (type: string) => makeCachedRequest(
            `type:${type}`,
            {
                url: `${repositoryDocPath}/${type}?type=${type}&stubs=true`,
                options: { method: 'GET' }
            }
        ),

        getRepositoryTemplateRefList: () => makeCachedRequest(
            'getRepositoryTemplateRefList',
            {
                url: apiUrl.repositoryTemplateRefList(),
                options: { method: 'GET' }
            }
        ),

        getDomainAvailabilityForTrial: (domainName: string, trialId: string, token: string) => {
            return makeRequest({
                url: apiUrl.trialDomainAvailable({ domainName, trialId, token }),
                options: {
                    method: 'GET'
                }
            });
        },

        getInstagramUserMedia: (accessToken: string) => {
            return makeRequest({
                url: apiUrl.instagramUserMedia({ accessToken }),
                options: {
                    method: 'GET'
                }
            });
        },

        getInstagramAccessToken: () => {
            return makeRequest({
                url: apiUrl.instagramAccessToken(),
                options: {
                    method: 'GET'
                }
            });
        },

        disconnectInstagram: () => {
            return makeRequest({
                url: apiUrl.disconnectInstagram(),
                options: {
                    method: 'POST'
                }
            });
        },

        exchangeForFacebookLongAccessToken: (accessToken: string, isPageMode: boolean = false) => {
            return makeRequest({
                url: apiUrl.facebookExchangeAccessToken({ accessToken, isPageMode, isFacebookFeed: true }),
                options: {
                    method: 'GET'
                }
            });
        },

        exchangeForFBChatLongAccessToken: (accessToken: string) => {
            return makeRequest({
                url: apiUrl.facebookExchangeAccessToken({ accessToken, isFacebookChat: true }),
                options: {
                    method: 'GET'
                }
            });
        },

        getFacebookFeedUserMedia: (accessTokens: Array<string>) => {
            return makeRequest({
                url: apiUrl.facebookFeedUserMedia({ accessTokens: JSON.stringify(accessTokens) }),
                options: {
                    method: 'GET'
                }
            });
        },

        toggleFacebookFeedPageVisibility: (fbAccountId: string, isVisible: boolean) => {
            return makeRequest({
                url: apiUrl.facebookFeedPageVisibility({ fbAccountId, isVisible }),
                options: {
                    method: 'POST',
                }
            });
        },

        getFacebookFeedAccessToken: () => {
            return makeRequest({
                url: apiUrl.facebookFeedAccessToken(),
                options: {
                    method: 'GET'
                }
            });
        },

        disconnectFacebookFeed: () => {
            return makeRequest({
                url: apiUrl.disconnectFacebookFeed(),
                options: {
                    method: 'POST'
                }
            });
        },

        getFacebookChatAccessToken: () => {
            return makeRequest({
                url: apiUrl.facebookChatAccessToken(),
                options: {
                    method: 'GET'
                }
            });
        },

        disconnectFacebookChat: () => {
            return makeRequest({
                url: apiUrl.disconnectFacebookChat(),
                options: {
                    method: 'POST'
                }
            });
        },

        getSocialMediaComponentsUsage: () => {
            return makeRequest({
                url: apiUrl.getSocialMediaComponentsUsage(),
                options: {
                    method: 'GET'
                }
            });
        },

        getPaidFeaturesDoc: () => {
            return makeRequest({
                url: apiUrl.paidFeaturesDoc(),
                options: {
                    method: 'GET'
                }
            });
        },

        createComponentPage: data => {
            const { timeout, ...rest } = data;
            return makeRequest({
                url: apiUrl.createComponentPage(),
                options: {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(rest),
                    timeout
                }
            });
        },

        getBlogDetails: () => {
            return makeRequest({
                url: `${defaultPath}/blog/getBlogDetails`,
                options: {
                    method: "GET",
                    timeout: "90s",
                }
            });
        },

        getBlogToken: () => {
            return makeRequest({
                url: apiUrl.blogToken(),
                options: {
                    method: "GET",
                    timeout: "90s",
                }
            });
        },

        getGeneratedAIText: data => {
            return makeRequest({
                url: apiUrl.generateAIText(),
                options: {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                },
                isStream: true
            });
        },

        regenerateTextWithAI: data => {
            return makeRequest({
                url: apiUrl.regenerateTextWithAI(),
                options: {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                },
                isStream: true
            });
        },

        getConceptForGmbKey: data => {
            return makeRequest({
                url: apiUrl.getConceptForGmbKey(),
                options: {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                }
            });
        },

        getGeneratedAIContent: data => {
            return makeRequest({
                url: apiUrl.getGeneratedAIContent(),
                options: {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                },
            });
        },

        generateSectionContent: data => {
            return makeRequest({
                url: apiUrl.generateSectionContent(),
                options: {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                },
            });
        },

        synonyms: data => {
            return makeRequest({
                url: apiUrl.synonyms(),
                options: {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                },
            });
        },

        /* === Unsplash === */
        getUnsplashCategories: (
            collection: Types.ThirdPartyImageCollectionT,
        ) => {
            let urlPath = apiUrl.unsplashCategories();
            return makeCachedRequest(`MediaCategories:${collection}`, {
                url: urlPath,
                options: {
                    method: 'GET'
                }
            }, true);
        },

        searchUnsplashImages: (collection: Types.ThirdPartyImageCollectionT, inQuery: Types.GetImagesQuery = {}) => {
            // filter out empty values
            const query = Object.keys(inQuery).reduce((acc, param) => (
                inQuery[param] === undefined || inQuery[param] === ''
                    ? acc
                    : { ...acc, [param]: inQuery[param] }
            ), {});

            return makeRequest({
                url: makeUrl({
                    path: apiUrl.unsplashImages(),
                    query,
                    options: {
                        encodeQuery: true
                    }
                }),
                options: {
                    method: 'GET',
                },
            });
        },

        getFreeOneComVideos: () => {
            return makeRequest({
                url: makeUrl({
                    path: apiUrl.getFreeOneComVideos(),
                }),
                options: {
                    method: 'GET',
                },
            });
        },

        generateScreenshot: () => {
            const query = {
                width: 1440,
                height: 960,
            };
            return makeRequest({
                url: makeUrl({
                    path: apiUrl.generateScreenshot(),
                    query,
                }),
                options: {
                    method: 'POST',
                },
            });
        },

        downloadFreeUnsplashImages: (request: Types.PostThirdPartyImageDownloadsRequest) => makeSaveRequest(
            apiUrl.unsplashImagesDownloads(),
            request,
            'POST'
        ),

        /* === Shutterstock === */

        getShutterstockCategories: (
            collection: Types.SstockCollectionT,
        ) => makeCachedRequest(`SstockCategories:${collection}`, {
            url: apiUrl.shutterStockCategories(collection),
            options: {
                method: 'GET'
            }
        }),

        getPaidShutterstockImagePrice: () => makeCachedRequest("shutterstockPaidImagePrice", {
            url: makeUrl({
                path: apiUrl.sstockImagesFullPrice(),
                // @ts-ignore
                query: getSstockImagesPurchaseMockQuery(),
            }),
            options: {
                method: 'GET',
            }
        }),

        getFreeShutterstockDownloadedImages: () => makeRequest({
            url: apiUrl.sstockFreeImagesDownloads(),
            options: {
                method: 'GET',
            },
        }),

        searchShutterstockImages: (collection: Types.SstockCollectionT, inQuery: Types.GetImagesQuery = {}) => {
            // filter out empty values
            const query = Object.keys(inQuery).reduce((acc, param) => (
                inQuery[param] === undefined || inQuery[param] === ''
                    ? acc
                    : { ...acc, [param]: inQuery[param] }
            ), {});

            return makeRequest({
                url: makeUrl({
                    path: apiUrl.sstockImages(collection),
                    query,
                    options: {
                        encodeQuery: true
                    }
                }),
                options: {
                    method: 'GET',
                },
            });
        },

        downloadFreeShutterstockImages: (request: Types.PostThirdPartyImageDownloadsRequest) => makeSaveRequest(
            apiUrl.sstockFreeImagesDownloads(),
            request,
            'POST'
        ),

        sstockImagesFullPurchageEndpoint: (): string => apiUrl.sstockImagesFullPurchage(),

        getAllDocsWithComponentWrappedInTable: () => makeRequest({
            url: defaultPath + '/getAllDocsWithComponentWrappedInTable',
            options: {
                method: 'GET'
            }
        }),

        getAnalyticsDependencies: () => makeRequest({
            url: makeUrl({ path: defaultPath + `/analyticsDependencies?r=${Math.random()}` }),  //NOSONAR
            options: {
                method: 'GET'
            }
        }),

        analyticsGoalAchieved: (goalId: string) =>
            makeRequest({
                url: makeUrl({
                    path: defaultPath + '/analyticsGoalAchieved',
                    query: { goalId }
                }),
                options: {
                    method: 'PUT'
                }
            }),

        getUserDataMigrationInfo: () => makeRequest({
            url: `${defaultPath}/migrate`,
            options: {
                method: 'GET'
            }
        }),

        migrateUserData: () => makeRequest({
            url: `${defaultPath}/migrate`,
            options: {
                method: 'POST'
            }
        }),

        checkReservedContent: () => makeRequest({
            url: `${defaultPath}/checkIfComponentHasReservedContent`,
            options: {
                method: 'GET'
            }
        }),

        checkGivenComponentsHaveReservedContent: (componentsContent: { [id: string]: string }) => {
            return makeRequest({
                url: `${defaultPath}/checkGivenComponentsHaveReservedContent`,
                options: {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(componentsContent)
                }
            });
        },

        makeFeaturedProductsPreviewUrl: query => {
            return makeUrl({
                path: defaultPath + '/' + WEBSHOP_PREVIEW + '/v2/iframe/featured-products-preview',
                query,
                options: { encodeQuery: true }
            });
        },

        getFeaturedProductsPageIds: () => {
            return makeRequest({
                url: apiUrl.getFeaturedProductsPageIds(),
                options: {
                    method: 'GET'
                }
            });
        },

        getConnectedBusinessData: () => {
            return makeRequest({
                url: defaultPath + '/gmb/getConnectedBusinessData',
                options: { method: 'GET', }
            });
        },

        getAccountsWithLocationsData: () => {
            return makeRequest({
                url: defaultPath + '/gmb/getAccountsWithLocationsData',
                options: { method: 'GET', }
            });
        },

        connectWithLocation: query => {
            return makeRequest({
                url: makeUrl({
                    path: defaultPath + '/gmb/connectWithLocation',
                    query
                }),
                options: { method: 'GET', }
            });
        },

        getGmbConnectionStatus: () => {
            return makeRequest({
                url: defaultPath + '/gmb/getConnectionStatus',
                options: { method: 'GET', }
            });
        },

        disconnectGmbListing: () => {
            return makeRequest({
                url: defaultPath + '/gmb/disconnect',
                options: { method: 'GET', }
            });
        },

        createConnectedBusinessData: data => {
            const url = makeUrl({
                path: defaultPath + '/gmb/createConnectedBusinessData',
                query: data,
                options: { encodeQuery: true }
            });

            return makeRequest({
                url,
                options: {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                }
            });
        },

        checkIfWebshopIsSetup: () => makeRequest({
            url: defaultPath + '/' + WEBSHOP_PREVIEW + '/domain',
            options: {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }
        }),

        fetchWebshopOnboardingStatus: () => makeRequest({
            url: defaultPath + '/' + WEBSHOP_PREVIEW + '/onboardStatus',
            options: {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }
        }),

        fetchWebShopMHFData: () => makeRequest({
            url: defaultPath + '/webshoppreview/shopinfo/',
            options: {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }
        }),

        fetchGoogleReviewsData: () => makeRequest({
            url: defaultPath + '/gmb/getReviews',
            options: {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }
        }),

        loadOverviewStepsStatus: () => {
            return makeRequest({
                url: makeUrl({ path: `/companionApp/v1/wsbStats` }),
                options: {
                    method: 'GET'
                }
            });
        },

        getKlikenSiteStatsUrl: () => {
            return makeRequest({
                url: makeUrl({ path: `${defaultPath}/kliken/sitestats` }),
                options: {
                    method: 'GET'
                }
            });
        },

        getKlikenApiAuthToken: () => {
            return makeRequest({
                url: makeUrl({ path: `${defaultPath}/kliken/authenticate` }),
                options: {
                    method: 'GET'
                }
            });
        },

        getKlikenSiteStatsSummary: (
            options: Types.KlikenSiteStatsSummaryRequest
        ): Promise<Types.DalResponseSet<Types.KlikenSiteStatsSummaryResponse>> => {
            const
                appConfig = getAppConfig(),
                apiBaseUrl = appConfig.oneWeb.kliken.apiBaseUrl,
                statsSummaryApi = appConfig.oneWeb.kliken.apis.statsSummary;

            return makeRequest({
                url: makeUrl({
                    path: `${apiBaseUrl}${statsSummaryApi}`,
                    query: {
                        StartDate: options.startDate,
                        EndDate: options.endDate,
                        IncludePastData: false
                    }
                }),
                noHeaders: true,
                options: {
                    method: 'GET',
                    headers: {
                        'Authorization': options.token,
                        'Accept': 'application/json'
                    }
                },
            });
        },

        getKlikenAccountDetails: (
            options: Types.KlikenAccountDetailsRequest
        ): Promise<Types.DalResponseSet<Types.KlikenAccountDetailsResponse>> => {
            const
                appConfig = getAppConfig(),
                apiBaseUrl = appConfig.oneWeb.kliken.apiBaseUrl,
                accountDetailsApis = appConfig.oneWeb.kliken.apis.accountDetails;

            return makeRequest({
                url: makeUrl({ path: `${apiBaseUrl}${accountDetailsApis}` }),
                noHeaders: true,
                options: {
                    method: 'GET',
                    headers: {
                        'Authorization': options.token,
                        'Accept': 'application/json'
                    }
                },
            });
        },

        enableTermlyForSite: (locale: string) => {
            return makeRequest({
                url: makeUrl({
                    path: `${defaultPath}/termly/enable`,
                    query: { locale }
                }),
                options: {
                    method: 'POST'
                }
            });
        },

        disableTermlyForSite: () => {
            return makeRequest({
                url: makeUrl({ path: `${defaultPath}/termly/disable` }),
                options: {
                    method: 'POST'
                }
            });
        },

        updateTermlyScanReport: () => {
            return makeRequest({
                url: makeUrl({ path: `${defaultPath}/termly/scan` }),
                options: {
                    method: 'POST'
                }
            });
        },

        createDefaultThirdPartyConnections: (data) => {
            return makeRequest({
                url: makeUrl({ path: `${defaultPath}/doc/thirdPartyConnections` }),
                options: {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data)
                }
            });
        },

        saveThirdPartyConnections: (data) => {
            return makeRequest({
                url: makeUrl({ path: `${defaultPath}/doc/thirdPartyConnections` }),
                options: {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data)
                }
            });
        },

        getThirdPartyConnections: () => {
            return makeRequest({
                url: makeUrl({ path: `${defaultPath}/doc/thirdPartyConnections` }),
                options: {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                }
            });
        },

        sendSlackAlert: (data) => {
            return makeRequest({
                url: apiUrl.slackAlerts(),
                options: {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                },
            });
        },

        getMarketgooAccount: () => {
            return makeRequest({
                url: makeUrl({
                    path: `${defaultPath}/marketgoo/account`
                }),
                options: {
                    method: 'GET'
                }
            });
        },
    };
};
